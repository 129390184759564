.btn {
  padding: 8px 20px !important;
  outline: none !important;
  border: none;
  cursor: pointer !important;
  white-space: nowrap !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.btn--primary {
  background-color: var(--CustomYellow) !important;
  color: #fff !important;
  border: solid var(--CustomYellow) !important;
  border-width: thin !important;
  font-size: clamp(1em, 4vw, 1.7em);
  font-weight: 700 !important;
}
.btn--outline {
  background-color: transparent !important;
  color: var(--CustomBlue) !important;
  border: 2px solid var(--CustomYellow) !important;
  font-weight: bold !important;
}
.btn--blue {
  color: #fff !important;
  background-color: var(--CustomBlue) !important;
  padding: 8.5px 22px !important;
  border: 1px solid var(--CustomBlue) !important;
  transition: all 0.3s ease-out !important;
  -webkit-transition: all 0.3s ease-out !important;
  -moz-transition: all 0.3s ease-out !important;
  -ms-transition: all 0.3s ease-out !important;
  -o-transition: all 0.3s ease-out !important;
  text-decoration: none !important;
}

.btn--medium {
  padding: 10px 22px !important;
  font-size: 1.5rem !important;

}

.btn--large {
  padding: 15px 36px !important;
  font-size: 1.5rem !important;
}
.btn--fixed {
  width: 20rem;
  font-size: 1.5rem !important;
}
.btn--mobile {
  text-align: center;
  border-radius: 4px;
  width: 80%;
  text-decoration: none;
  font-size: 1.5rem;
  background-color: transparent;
  color: #fff;
  padding: 14px 20px;
  border: 1px solid #fff;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}


.btn--large:hover,
.btn--mobile:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.btn--blue:hover {
  transition: all 0.3s ease-out !important;
  background: var(--CustomYellow) !important;
  color: #fff !important;
  border: 1px solid var(--CustomYellow) !important;
  -webkit-transition: all 0.3s ease-out !important;
  -moz-transition: all 0.3s ease-out !important;
  -ms-transition: all 0.3s ease-out !important;
  -o-transition: all 0.3s ease-out !important;
}

.btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  /* padding: 8px 16px; */
  height: 100%;
  width: 100%;
}

.blue {
  background-color: #276afb;
  color: #fff;
  border: none;
}

.primary {
  background-color: #242424;
  color: #fff;
  border: none;
}

.primary:hover {
  background-color: #fff;
  color: #242424;
  border: none;
}
